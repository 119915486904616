import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react"
import { withSentry } from "@sentry/remix"
import { useEffect, type ReactNode } from "react"
import { getToast } from "remix-toast"
import { Toaster, toast as showToast } from "sonner"
import { DesktopOnly } from "@/widgets/desktop-only"
import { getAuthFromRequest } from "@/features/auth.server"
import { isSidebarHiddenCookie } from "@/shared/ui/layout"

import "./tailwind.css"

////////////////////////////////////////
////              Meta              ////
////////////////////////////////////////
export const meta: MetaFunction = () => [
  { name: "description", content: "Flow — единая IT система QQ Coffee" },
  {
    title: "Flow",
  },
]

////////////////////////////////////////
////             Links              ////
////////////////////////////////////////
export const links: LinksFunction = () => [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "256x256",
    href: "/apple-touch-icon-256x256.png",
  },
  {
    rel: "icon",
    href: "/favicon.ico",
  },
  {
    rel: "preconnect",
    href: "https://rsms.me/",
  },
  {
    rel: "stylesheet",
    href: "https://rsms.me/inter/inter.css",
  },
]

////////////////////////////////////////
////          Root loader           ////
////////////////////////////////////////
export async function loader({ request }: LoaderFunctionArgs) {
  const { viewer } = await getAuthFromRequest(request, { isRequired: false })
  const { toast, headers } = await getToast(request)

  const isSidebarHidden = (await isSidebarHiddenCookie!.parse(
    request.headers.get("Cookie"),
  )) as boolean

  return json(
    {
      viewer,
      toast,
      isSidebarHidden,
    },
    { headers },
  )
}

////////////////////////////////////////
////         Error boundary         ////
////////////////////////////////////////
export { RootErrorBoundary as ErrorBoundary } from "@/widgets/root-error-boundary"

////////////////////////////////////////
////             Layout             ////
////////////////////////////////////////
export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="ru">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="og:image" content="https://qqcoffee.ru/open-graph.png" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}

        <Scripts />
        <ScrollRestoration />
      </body>
    </html>
  )
}

////////////////////////////////////////
////              App               ////
////////////////////////////////////////
function App() {
  const { toast } = useLoaderData<typeof loader>()

  useEffect(() => {
    if (toast?.type === "error") {
      showToast.error(toast.message, {
        description: toast.description,
        duration: toast.duration,
      })
      return
    }

    if (toast?.type === "success") {
      showToast.success(toast.message, {
        description: toast.description,
        duration: toast.duration,
      })
    }
  }, [toast])

  return (
    <>
      <DesktopOnly>
        <Outlet />
      </DesktopOnly>
      <Toaster position="bottom-center" />
    </>
  )
}

export default withSentry(App)
